import React, { FC } from 'react';
import { RichTextType } from '../../../models/rich-text-type';
import RichTextComponent from '../../molecules/rich-text/rich-text';

const RichText: FC<RichTextType> = ({ richTextContent }: RichTextType) => (
  <RichTextComponent className="py-component-spacing-mobile lg:py-component-spacing-content mx-auto w-full md:w-10/12 lg:w-8/12">
    {richTextContent}
  </RichTextComponent>
);

export default RichText;
